<template>
    <v-card elevation="12" shaped class="ma-2">
        <v-card-title>
            <v-row justify="center">
                {{ title }}
            </v-row>
        </v-card-title>
        <v-card-subtitle>
            <div class="ma-4 text-center" ref="box">
                <v-progress-circular :rotate="90" :size="progressBarSize" :indeterminate="indeterminate" :width="progressBarWidth" :value="progress" :color="colorProgress">
                    <div>
                        <h4 class="grey--text">Especialistas</h4>
                        <br />
                        <h3>Planejado: {{ item.total }}</h3>
                        <h3>Realizado: {{ item.done }}</h3>
                    </div>
                </v-progress-circular>
            </div>
        </v-card-subtitle>

        <v-divider />
        <v-card-text v-if="storesInError.length > 0">
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">Loja</th>
                            <!-- <th class="text-left">Planejado</th>
                            <th class="text-left">Realizado</th>
                            <th class="text-left">Fora de hora</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in storesInError" :key="item.name">
                            <td :class="item.status == 2 ? 'error--text' : 'warning--text'">{{ item.name }}</td>
                            <!-- <td class="red--text">{{ item.total }}</td>
                            <td class="red--text">{{ item.done }}</td>
                            <td class="red--text">{{ item.error }}</td> -->
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>

        <v-card-actions v-if="extraStores.length > 0">
            <v-btn color="orange lighten-2" text v-if="!show" @click="show = !show"> Ver mais </v-btn>
            <v-btn color="grey lighten-2" text v-else @click="show = !show"> Fechar </v-btn>

            <v-spacer></v-spacer>

            <v-btn icon @click="show = !show">
                <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
            </v-btn>
        </v-card-actions>

        <v-expand-transition v-if="extraStores.length > 0">
            <div v-show="show">
                <v-divider />
                <v-card-text>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">Loja</th>
                                    <!-- <th class="text-left">Planejado</th>
                                    <th class="text-left">Realizado</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in extraStores" :key="item.name">
                                    <td :class="item.status == 1 ? 'success--text' : 'grey--text'">{{ item.name }}</td>
                                    <!-- <td :class="item.status == 1 ? 'green--text' : 'grey--text'">{{ item.total }}</td>
                                    <td :class="item.status == 1 ? 'green--text' : 'grey--text'">{{ item.done }}</td> -->
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-divider class="my-2" />
                    <h5 class="grey--text">Total de lojas: {{ item.stores.length }}</h5>
                </v-card-text>
            </div>
        </v-expand-transition>
    </v-card>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "-",
        },
        item: {
            type: Object,
        },
    },

    data() {
        return {
            show: false,
            progressBarSize: 100,
            progressBarWidth: 10,
            progress: 0,
            colorProgress: "grey",
            indeterminate: true,
        };
    },

    watch: {
        item: {
            immediate: true,
            handler(newVal) {
                this.calculate(newVal);
            },
        },
    },

    computed: {
        extraStores() {
            return this.item && this.item.stores ? this.item.stores.filter((i) => i.status < 2) : [];
        },
        storesInError() {
            return this.item && this.item.stores ? this.item.stores.filter((i) => i.status >= 2) : [];
        },
    },

    methods: {
        calculate(item) {
            if (item) {
                if (item.total >= item.done && item.total > 0) {
                    this.indeterminate = false;
                    this.progress = (item.done / item.total) * 100;

                    if (this.progress == 100) {
                        this.colorProgress = "success";
                    } else if (this.progress > 0) {
                        this.colorProgress = "primary";
                    } else {
                        this.colorProgress = "grey";
                    }
                } else {
                    this.indeterminate = true;
                }
            }
        },
        calculateSize() {
            let wi = this.$refs && this.$refs.box && this.$refs.box.clientWidth ? this.$refs.box.clientWidth : 500;

            this.progressBarSize = wi * 0.9;
            this.progressBarWidth = this.progressBarSize * 0.12;
        },
    },

    mounted() {
        this.calculateSize();
        this.calculate(this.item);
    },

    created() {
        window.addEventListener("resize", this.calculateSize);
    },

    destroyed() {
        window.removeEventListener("resize", this.calculateSize);
    },
};
</script>
