<template>
    <div>
        <v-layout wrap class="mb-12">
            <v-flex xs12 class="my-4 text-center">
                <h1>Relatório {{ $moment().format("DD/MM/YYYY") }}</h1>
                <v-divider />
            </v-flex>

            <v-flex xs12>
                <v-alert v-if="!hasData || error" type="error" outlined>
                    <div class="text-h6">
                        {{ $t("Não foi localizado nenhuma informação.") }}
                    </div>
                    <div>{{ $t("Solicite ao administrador do sistema a verificação do seu cadastro.") }}</div>
                </v-alert>
            </v-flex>
        </v-layout>

        <v-layout wrap class="mb-12" v-if="hasData">
            <v-flex xs12 sm6 lg3>
                <Box title="Inicio das operações" :item="item.start" />
            </v-flex>
            <v-flex xs12 sm6 lg3 v-if="item.startInterval && item.startInterval.total > 0">
                <Box title="Inicio do intervalo" :item="item.startInterval" />
            </v-flex>
            <v-flex xs12 sm6 lg3 v-if="item.stopInterval && item.stopInterval.total > 0">
                <Box title="Retorno do intervalo" :item="item.stopInterval" />
            </v-flex>
            <v-flex xs12 sm6 lg3>
                <Box title="Final das operações" :item="item.stop" />
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
import Box from "./Box";

export default {
    components: { Box },

    data() {
        return {
            timer: null,
            show: false,
            error: true,
            interval: {},
            item: { start: {}, startInterval: {}, stopInterval: {}, stop: {} },
        };
    },

    methods: {
        update() {
            this.$http
                .get(`partners/dashboard-operation`, { continuous: true })
                .then((result) => {
                    if (result) {
                        this.error = false;
                        this.item = result;
                    } else {
                        this.error = true;
                    }
                })
                .catch(() => (this.error = true));
        },
    },

    mounted() {
        this.update();
        this.timer = setInterval(() => this.update(), 10000);
    },

    computed: {
        hasData() {
            return this.item && this.item.start && this.item.start.total > 0;
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>
